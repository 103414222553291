'use strict'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class TimelineMarkers extends Component {
  static propTypes = {
    alwaysPath: PropTypes.bool,
    className: PropTypes.string,
    customMarker: PropTypes.func,
    edge: PropTypes.number,
    group: PropTypes.number,
    length: PropTypes.number,
    markers: PropTypes.array,
    side: PropTypes.number,
    sizeScale: PropTypes.number,
    styles: PropTypes.object,
    vertical: PropTypes.bool,
    width: PropTypes.number,
  }

  static defaultProps = {
    sizeScale: 1,
  }

  render() {
    const {
      vertical,
      width,
      length,
      edge,
      markers,
      styles,
      side,
      group,
      className,
      customMarker,
      sizeScale,
      alwaysPath,
    } = this.props
    // console.log('timeline markers:', this.props);

    // marker format:
    // {
    //   t: 334.567,    // pixel position in time axis from the start of the timeline ruler
    //   d: 20.1,       // pixel delta between start and end of marker line in time axis
    //   p: 200,        // position in space (often the distance from ruler edge + the track pos)
    //   k: 'inside',   // type of marker for index on styles object
    //   a: 1           // alpha
    // }

    const style = {}
    let w, h
    if (vertical) {
      w = width
      h = length

      if (side > 0) {
        style.left = `${edge}px`
      } else {
        style.left = `${edge - width}px`
      }
    } else {
      w = length
      h = width
      if (side > 0) {
        style.top = `${edge}px`
      } else {
        style.top = `${edge - width}px`
      }
    }

    let pp = -100
    const lines = []
    markers.forEach((marker, idx) => {
      let x1, y1, xc1, yc1, x2, y2, xc2, yc2
      const d = marker.d || 0
      const k = marker.k

      const shape = styles[k].shape
      const ends = styles[k].ends
      const offset1 = (styles[k].offset1 || 0) * sizeScale // offset from ruler edge
      const offset2 = (styles[k].offset2 || 0) * sizeScale // offset from item
      const offset3 = styles[k].offset3 || 0 // endpoint offset in time direction
      const radius = styles[k].radius || 0
      const skipThreshold = group || radius
      const curviness = styles[k].curviness || 0
      const lineStyle = styles[k].line
      const dotStyle = styles[k].marker
      const start = /s$/.test(k) ? 1 : -1
      const inRange = !marker.o

      const f =
        curviness === 0
          ? 0.01
          : Math.min(
              Math.max(
                Math.abs(d) / ((marker.p - offset1 - offset2) * curviness),
                0
              ),
              1
            ) * curviness

      if (vertical) {
        x1 = offset1
        y1 = marker.t

        if (!inRange && y1 - pp < skipThreshold) {
          return
        }
        pp = y1
        x2 = marker.p - offset2
        xc1 = x2 * f
        xc2 = x2 - xc1
        y2 = y1 + d + start * offset3
        yc2 = y2
        yc1 = y1

        if (side < 0) {
          x1 = width - x1
          x2 = width - x2
          xc1 = width - xc1
          xc2 = width - xc2
        }
      } else {
        y1 = offset1
        x1 = marker.t

        if (!inRange && x1 - pp < skipThreshold) {
          return
        }
        pp = x1

        yc1 = (marker.p - offset2) * f
        xc1 = x1
        y2 = marker.p - offset2
        x2 = x1 + d + start * offset3
        yc2 = marker.p - offset2 - yc1
        xc2 = x2

        if (side < 0) {
          y1 = width - y1
          y2 = width - y2
          yc1 = width - yc1
          yc2 = width - yc2
        }
      }

      x1 = x1.toFixed(1) * 1
      x2 = x2.toFixed(1) * 1
      xc1 = xc1.toFixed(1) * 1
      xc2 = xc2.toFixed(1) * 1
      y1 = y1.toFixed(1) * 1
      // y1 = Math.floor(y1);
      // y2 = y2.toFixed(1) * 1;
      y2 = Math.floor(y2)
      yc1 = yc1.toFixed(1) * 1
      yc2 = yc2.toFixed(1) * 1

      if (typeof marker.a === 'undefined') {
        marker.a = 1
      }

      let path, startDot, endDot

      if (shape && radius > 0 && ends !== 'none' && marker.a > 0) {
        if (shape === 'square') {
          if (!ends || ends === 'start') {
            startDot = (
              <rect
                x={x1 - radius}
                y={y1 - radius}
                width={radius * 2}
                height={radius * 2}
                style={dotStyle}
              />
            )
          }

          if (inRange && (!ends || ends === 'end')) {
            endDot = (
              <rect
                x={x2 - radius}
                y={y2 - radius}
                width={radius * 2}
                height={radius * 2}
                style={dotStyle}
                opacity={marker.a}
              />
            )
          }
        } else if (shape === 'diamond') {
          if (!ends || ends === 'start') {
            startDot = (
              <path
                d={`M${x1 - radius},${y1}L${x1},${y1 - radius}L${
                  x1 + radius
                },${y1}L${x1},${y1 + radius}z`}
                style={dotStyle}
              />
            )
          }
          if (inRange && (!ends || ends === 'end')) {
            endDot = (
              <path
                d={`M${x2 - radius},${y2}L${x2},${y2 - radius}L${
                  x2 + radius
                },${y2}L${x2},${y2 + radius}z`}
                style={dotStyle}
                opacity={marker.a}
              />
            )
          }
        } else {
          if (!ends || ends === 'start') {
            startDot = <circle cx={x1} cy={y1} r={radius} style={dotStyle} />
          }
          if (inRange && (!ends || ends === 'end')) {
            endDot = (
              <circle
                cx={x2}
                cy={y2}
                r={radius}
                style={dotStyle}
                opacity={marker.a}
              />
            )
          }
        }
      }

      if (inRange && lineStyle.strokeWidth && marker.a > 0) {
        y1 += lineStyle.strokeWidth / 2
        y2 += lineStyle.strokeWidth / 2
        if (Math.abs(y2 - y1) < 5 && !alwaysPath) {
          let _props = {
            x1,
            y1,
            x2,
            y2,
            style: Object.assign({}, lineStyle),
            opacity: marker.a,
          }

          if (customMarker) {
            _props = customMarker('line', marker, _props)
          }
          path = <line {..._props} />
        } else {
          const xa = shape === 'diamond' && ends === 'start' ? radius : 0
          const xb = shape === 'diamond' && ends === 'end' ? radius : 0
          let _props = {
            d: `M${x1 + xa},${y1} C${xc1},${yc1} ${xc2},${yc2} ${
              x2 - xb
            },${y2}`,
            style: Object.assign({}, lineStyle),
            opacity: marker.a,
          }

          if (customMarker) {
            _props = customMarker('path', marker, _props)
          }
          path = <path {..._props} />
        }
      }

      if (path || startDot) {
        const classes = classNames({
          invisible: !marker.visible,
          active: marker.active,

          visible: marker.visible,
          focused: marker.focused,
        })
        lines.push(
          <g key={idx} className={classes}>
            {path}
            {startDot}
            {endDot}
          </g>
        )
      }
    })

    const _className = `timeline-markers ${className || ''}`

    return (
      <svg
        width={w}
        height={h}
        className={_className}
        version="1.1"
        style={style}
      >
        {lines}
      </svg>
    )
  }
}
