import { ApolloConsumer, gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import settings from '@config/settings'
import { clearCookie, setCookie } from '@lib/cookies'
import Logo from '@views/app/components/Logo'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

export class Login extends PureComponent {
  static propTypes = {
    newSession: PropTypes.func,
    history: PropTypes.object,
    client: PropTypes.object,
    redirectTo: PropTypes.string,
  }

  static defaultProps = {
    redirectTo: settings.defaultPath,
  }

  state = {
    status: '',
  }

  componentDidMount() {
    this.refEmail?.focus()
  }

  onSubmit = (event) => {
    const { client, newSession, history, redirectTo } = this.props
    event.preventDefault()

    const email = this.refEmail.value
    if (!email) {
      this.refEmail.focus()
      return
    }

    const password = this.refPassword.value
    if (!password) {
      this.refPassword.focus()
      return
    }

    client.resetStore()
    clearCookie(settings.jwtKey)
    this.setState({ status: 'pending' })

    newSession({
      variables: { email, password },
    })
      .then((res) => {
        const session = res.data.newSession
        // console.log('login session', session)

        if (settings.useSentry) {
          require('@sentry/browser').configureScope((scope) => {
            scope.setUser({
              email: session.user.email,
              username: session.user.name,
            })
          })
        }

        if (!session?.token) {
          this.setState({ status: 'Invalid email or password' })
          return
        }

        setCookie(settings.jwtKey, session.token, session.expires)

        // prevent infinite login loop!
        const url =
          /login/.test(redirectTo) || !redirectTo ? '/home' : redirectTo
        // console.log('new session, now redirect to:', redirectTo, url, session)
        delete window._leError
        history.replace(url)
      })
      .catch((err) => {
        console.warn('login error')
        clearCookie(settings.jwtKey)
        this.setState({
          status: /network error/i.test(err.message)
            ? 'Network error, please try again'
            : 'Invalid email or password',
        })
      })
  }

  setEmailRef = (ref) => (this.refEmail = ref)
  setPasswordRef = (ref) => (this.refPassword = ref)

  render() {
    const { status } = this.state
    const _status = window._leError || status

    let loginButton, errorMsg
    if (status === 'pending') {
      loginButton = (
        <button type="submit" className="btn btn-primary login-button" disabled>
          Logging in...
        </button>
      )
    } else {
      if (_status && _status !== 'pending') {
        errorMsg = <p className="form-error">{_status}</p>
      }

      loginButton = (
        <button type="submit" className="btn btn-primary login-button">
          Login
        </button>
      )
    }

    return (
      <form
        method="post"
        className="popup form login-form center"
        onSubmit={this.onSubmit}
      >
        <Logo />
        {errorMsg}
        <fieldset className="fieldset">
          <p className="form-row">
            <label htmlFor="login-email">Email</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="login-email"
              className="input input-email block"
              ref={this.setEmailRef}
            />
          </p>

          <p className="form-row">
            <label htmlFor="login-password">Password</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              id="login-password"
              className="input input-password block"
              ref={this.setPasswordRef}
            />
          </p>
        </fieldset>

        <fieldset className="fieldset">{loginButton}</fieldset>
      </form>
    )
  }
}

export const NEW_SESSION_MUTATION = gql`
  mutation NewSession($email: String!, $password: String!) {
    newSession(email: $email, password: $password) {
      token
      expires
      user {
        id
        name
        email
        role
        data
      }
    }
  }
`

const WithMutation = graphql(NEW_SESSION_MUTATION, { name: 'newSession' })(
  Login
)

export default (props) => (
  <ApolloConsumer>
    {(client) => <WithMutation {...props} client={client} />}
  </ApolloConsumer>
)
