/**
 * Safe deep setIn functions, returns new object
 */
export function setIn(srcObj, path, val) {
  const obj = JSON.parse(JSON.stringify(Object.assign({}, srcObj)))
  const keys = (typeof path === 'string' ? path.split('.') : path).filter(k => k)

  if (!keys.length) {
    return val
  }

  const lastKey = keys.slice(-1)
  const lastObj = keys.slice(0, -1).reduce((objArg, key) => (objArg[key] = objArg[key] || {}), obj)
  lastObj[lastKey] = val
  return obj
}

/**
 * Safe deep getIn
 */
export function getIn(obj, path) {
  const keys = (typeof path === 'string' ? path.split('.') : path)
    .filter(k => k !== '' && k !== undefined)
    .map(k => (isFinite(k) ? parseInt(k) : k))

  if (!obj || !keys.length) {
    return
  }

  let v = obj
  for (const k of keys) {
    v = v[k]
    if (v === undefined) {
      break
    }
  }

  return v
}

export function balanceBreak(text, breakAfter = 25) {
  if (text.length < breakAfter) {
    return [text]
  }

  const rows = Math.ceil(text.length / breakAfter)
  const len = Math.ceil(text.length / rows)

  const lines = []
  const str = text
  let i = 0
  let j = len

  while (str[j]) {
    if (/[- ]/.test(str[j])) {
      lines.push(str.substr(i, j - i + 1).trim())
      j++
      i = j
      j += len
    } else {
      j++
    }
  }

  // last line
  lines.push(str.substr(i).trim())

  // check for orphan words and append to previous line if so
  if (!/\s/.test(lines[lines.length - 1])) {
    const lastLine = lines.pop()
    lines[lines.length - 1] += ' ' + lastLine
  }

  return lines
}

export function langVal(obj, key, lang = '') {
  return (lang && lang !== 'en' && obj.getIn([key, `value-${lang}`])) || obj.getIn([key, 'value'])
}

export function timelineClickHandler(event, positions) {
  if (!positions || positions.length < 2) {
    return 0
  }

  const p = event.detail.pointer
  let idx = 0
  while (positions[idx]) {
    if (positions[idx].t > p) {
      // are we closer to the prev event than the current one?
      // if so choose the prev event as the index to scroll to
      if (positions[idx - 1]) {
        const dt = positions[idx].t - positions[idx - 1].t
        const t = p - positions[idx - 1].t // since since the prev event to pointer
        if (t / dt < 0.5) {
          idx--
        }
      }
      break
    }
    idx++
  }

  // find the closest item to where we clicked
  idx = Math.min(Math.max(idx, 0), positions.length - 1)
  // console.log('clicked timeline', p, idx, positions)
  return positions[idx].p
}

export function getPreviewId(query) {
  const isPreview = /previewid/.test(query)
  const previewId = isPreview ? query.match(/previewid=(\d+)/)[1] : ''
  return parseInt(previewId) || 0
}

export function keyBy(array, key) {
  if (!array && !array.length) {
    return {}
  }
  return array.reduce((acc, item) => {
    acc[item[key]] = item
    return acc
  }, {})

}

export function fetchPartnerIds (items, excludedTplIds = []) {  
  const filteredItems = items?.filter((item) => !excludedTplIds.includes(item.templateId))
  return Array.from(
    new Set(filteredItems?.map((w) => w.partner).filter(Boolean))
  )
}

export function isPartnerSelected(partnerId, selected) {
  return !!partnerId && selected?.some((p) => p.id === partnerId)
}

export function isContentVisible (partnerId, selected) {
  return !partnerId || isPartnerSelected(partnerId, selected)
} 

export function filterVisibleContent(items, selected) {
  return items?.filter((item) => isContentVisible(item.partner, selected))||[]
}
    
