export const templateIds = {
  eventTplId: 1012,
  authorTplId: 1001,
  articleTplId: 1002,
  publicationTplId: 1007,
  comparisonTplId: 1008,
  connectionTplId: 1009,
  erasTplId: 1011,
  worldEventTplId: 1016,
  workTplId: 1017,
  compareId: 8,
}

export const timelineLayouts = {
  default: '6',
  compare: '7',
}

export const CURRENT_TIME = 9999

export const authorMarkerStyles = {
  startMarker: {
    active: true,
    line: {
      curviness: 0,
      dash1: 0,
      offset1: 0,
      offset2: -20,
      offset3: 0,
      // stroke: 'rgba(255, 77, 0, 1)',
      stroke: '#F68F26',
      strokeWidth: 2,
    },
  },
  endMarker: {
    active: true,
    line: {
      curviness: 0,
      dash1: 0,
      offset1: 0,
      offset2: -20,
      offset3: 0,
      // stroke: 'rgba(255, 77, 0, 1)',
      stroke: '#F68F26',
      strokeWidth: 2,
    },
  },
}

export const itemMarkerStyles = {
  startMarker: {
    active: true,
    line: {
      curviness: 0.6,
      dash1: 0,
      offset1: 5,
      offset2: 70,
      offset3: 0,
      stroke: 'rgba(148, 140, 129, 0.3)',
      strokeWidth: 1.5,
    },
    marker: {
      fill: 'rgba(148, 140, 129, 1)',
      radius: 4.5,
      shape: 'diamond',
      ends: 'end',
      stroke: 'none',
    },
  },
  endMarker: {
    active: false,
  },
  outsideMarker: {
    active: true,
    line: {
      curviness: 0.6,
      dash1: 0,
      offset1: 6,
      offset2: 100,
      offset3: 0,
    },
    marker: {
      fill: 'rgba(148, 140, 129, 1)',
      radius: 3.5,
      shape: 'diamond',
      stroke: 'none',
    },
  },
}

export const listMarkerStyles = {
  inside: {
    active: true,
    offset1: 8,
    offset2: 12,
    offset3: 0,
    curviness: 0.6,
    radius: 4.5,
    shape: 'circle',
    ends: 'end',
    line: {
      fill: 'none',
      dash1: 0,
      stroke: 'rgba(148, 140, 129, 0.3)',
      strokeWidth: 1.5,
    },
    marker: {
      fill: 'rgba(148, 140, 129, 1)',
      stroke: 'none',
    },
  },
  outside: {
    active: true,
    offset1: 6,
    offset2: 0,
    offset3: 0,
    curviness: 0.6,
    radius: 3.5,
    shape: 'circle',
    ends: 'start',
    line: {
      fill: 'none',
    },
    marker: {
      fill: 'rgba(148, 140, 129, 1)',
      stroke: 'none',
    },
  },
  activeInside: {
    active: true,
    offset1: 8,
    offset2: 12,
    offset3: 0,
    curviness: 0.6,
    radius: 4.5,
    shape: 'circle',
    ends: 'end',
    line: {
      fill: 'none',
      dash1: 0,
      stroke: 'rgba(246, 143, 38, 1)',
      strokeWidth: 1.5,
    },
    marker: {
      fill: 'rgba(246, 143, 38, 1)',
      stroke: 'none',
    },
  },
  activeOutside: {
    active: true,
    offset1: 6,
    offset2: 0,
    offset3: 0,
    curviness: 0.6,
    radius: 3.5,
    shape: 'circle',
    ends: 'start',
    line: {
      fill: 'none',
    },
    marker: {
      fill: 'rgba(246, 143, 38, 1)',
      stroke: 'none',
    },
  },
}

export const overviewMarkerStyles = {
  inside: {
    active: true,
    offset1: 0,
    offset2: 12,
    offset3: 0,
    curviness: 0.6,
    radius: 3.5,
    shape: 'diamond',
    ends: 'end',
    line: {
      fill: 'none',
      dash1: 0,
      stroke: 'rgba(148, 140, 129, 0.3)',
      strokeWidth: 1.5,
    },
    marker: {
      fill: 'rgba(148, 140, 129, 1)',
      stroke: 'none',
    },
  },
  activeInside: {
    active: true,
    offset1: 8,
    offset2: 12,
    offset3: 0,
    curviness: 0.6,
    radius: 4.5,
    shape: 'diamond',
    ends: 'none',
    line: {
      fill: 'none',
      dash1: 0,
      stroke: 'rgba(246, 143, 38, 1)',
      strokeWidth: 1.5,
    },
    marker: {
      fill: 'rgba(246, 143, 38, 1)',
      stroke: 'none',
    },
  },
}

export const templateLayouts = {
  [templateIds.authorTplId]: {
    rulerOffset: 15,
    size: 40,
    spacing: 0,
    timeOffset: 0,
    timeSpacing: 0,
    className: 'person-item',
    trackGroupSize: '75px',
  },
  chrono: {
    rulerOffset: 155,
    size: 350,
    spacing: 0,
    timeOffset: -15,
    timeSpacing: 5,
    className: 'chrono-item',
  },
}
